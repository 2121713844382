/* Dashboard.css - With unique class names for enrolled courses + middle screen layout */

/* Welcome Section */
.hello {
  margin-top: 90px;
  width: 82%;
  border: solid #357717 50px;
  background-color: #357717;
}

.hello-text {
  font-size: 70px;
  color: white;
  margin-left: 100px;
}

/* Layout and Container */
.grid-container-wrapper {
  margin-top: 75px;
  margin-left: 80px;
  width: calc(100% - 300px);
}

/* Headers */
.header-courses,
.header-recommended {
  text-align: left;
  font-size: 34px;
}

.header-courses {
  margin-bottom: 40px;
}

.header-recommended {
  margin: 30px 0 20px;
}

/* ===== ENROLLED COURSES - UNIQUE CLASS NAMES ===== */
/* Container for enrolled courses */
.enrolled-courses-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 90%;
  margin-bottom: 30px;
  margin-left: 0;
}

/* Individual enrolled course card */
.enrolled-course-card {
  position: relative;
  background: white;
  border-radius: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease;
  min-height: 550px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.enrolled-course-card:hover {
  transform: scale(1.02);
}

/* Course Images */
.enrolled-course-image {
  max-width: 250px;
  max-height: 250px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease;
  margin-bottom: 30px;
}

.enrolled-course-image:hover {
  transform: scale(1.05);
}

/* Specific class image styles */
.enrolled-coding1-img,
.enrolled-coding2-img,
.enrolled-biochem-img,
.enrolled-genetic-img,
.enrolled-microbio-img {
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease;
  margin-bottom: 30px;
}

/* Course Content */
.enrolled-course-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.enrolled-course-content h1 {
  font-size: 38px;
  margin: 0 0 15px 0;
  color: #000;
  font-weight: bold;
}

.enrolled-course-content h2 {
  font-size: 18px;
  margin: 0 0 15px 0;
  color: #000;
  font-weight: normal;
  line-height: 1.5;
  max-width: 80%;
}

/* Delete Course Button */
.enrolled-delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: red;
  background: none;
  border: none;
  font-size: 20px;
  z-index: 5;
}

/* Loading spinner for delete */
.enrolled-loading-spinner {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: enrolled-spin 1s linear infinite;
  z-index: 5;
}

@keyframes enrolled-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Button Container */
.enrolled-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

/* Zoom Button */
.enrolled-zoom-btn {
  font-size: 18px;
  padding: 12px 0;
  width: 200px;
  color: white;
  background-color: #5d86c5;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Worksheet Button */
.enrolled-worksheet-btn {
  font-size: 18px;
  padding: 12px 0;
  width: 200px;
  color: white;
  background-color: #1d5f1b;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.enrolled-zoom-btn:hover,
.enrolled-worksheet-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.enrolled-zoom-btn:active,
.enrolled-worksheet-btn:active {
  transform: translateY(1px);
}

/* Tooltip styles for buttons */
.enrolled-tooltip {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  white-space: nowrap;
  z-index: 10;
}

.enrolled-zoom-btn:hover .enrolled-tooltip,
.enrolled-worksheet-btn:hover .enrolled-tooltip {
  opacity: 1;
  visibility: visible;
}

/* Empty state container */
.enrolled-empty-state {
  text-align: center;
  margin: 30px 0;
}

.enrolled-empty-message {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 10px;
  text-align: center;
  color: #666;
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.enrolled-loading-message {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin-top: 20px;
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.enrolled-explore-btn {
  width: 100%;
  max-width: 400px;
  height: 60px;
  font-size: 25px;
  border-radius: 10px;
  background: #357717;
  color: white;
  border: none;
  transition: all 0.3s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding: 20px 30px;
  cursor: pointer;
}

.enrolled-explore-btn:hover {
  transform: translateY(-2px);
  opacity: 0.9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* ===== RECOMMENDED COURSES SECTION ===== */
.recommended-container {
  width: 100%;
  max-width: calc(100% - 30px);
}

.recommended-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.course-card {
  width: calc(25% - 20px);
  min-width: 200px;
  max-width: 250px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin-bottom: 20px;
  overflow: hidden;
}

.course-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.course-card-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  display: block;
  margin: 1rem auto;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.course-card-image:hover {
  transform: scale(1.05);
}

.course-card-content {
  padding: 1.5rem;
  text-align: center;
}

.course-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.course-card-description {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.5;
}

/* Middle screens (1000px - 1400px) */
@media screen and (max-width: 1400px) and (min-width: 1000px) {
  /* Welcome Section */
  .hello {
    width: 90%;
    border-width: 30px;
  }

  .hello-text {
    font-size: 50px;
    margin-left: 50px;
  }

  /* Layout */
  .grid-container-wrapper {
    width: 85%;
    margin-left: 100px; /* Shifted right for middle screen */
    max-width: 1000px; /* Increased max width */
  }

  /* Headers */
  .header-courses,
  .header-recommended {
    font-size: 28px;
  }

  /* Enrolled Courses - Middle Screen */
  .enrolled-courses-grid {
    grid-template-columns: 1fr;
    width: 85%;
    max-width: 800px; /* Wider cards */
    margin-left: 0;
  }
  
  .enrolled-course-card {
    padding: 40px;
    min-height: 0;
    margin-bottom: 40px; /* More space between cards */
  }

  .enrolled-course-image,
  .enrolled-coding1-img,
  .enrolled-coding2-img,
  .enrolled-biochem-img,
  .enrolled-genetic-img,
  .enrolled-microbio-img {
    max-width: 250px;
    max-height: 250px;
  }

  .enrolled-course-content h1 {
    font-size: 32px;
  }

  .enrolled-course-content h2 {
    font-size: 16px;
    max-width: 90%;
  }

  /* Button placement for middle screen */
  .enrolled-button-container {
    margin-top: 20px;
    width: 100%;
  }

  .enrolled-zoom-btn,
  .enrolled-worksheet-btn {
    width: 160px;
    font-size: 16px;
    padding: 10px 0;
  }

  /* Recommended courses */
  .recommended-container {
    width: 100%;
    max-width: 100%;
  }

  .course-card {
    width: calc(33.33% - 20px);
  }

  .enrolled-explore-btn {
    max-width: 350px;
    height: 55px;
    font-size: 20px;
  }
}

/* Small screens (below 1000px) */
@media screen and (max-width: 1000px) {
  .hello {
    width: 90%;
    border-width: 30px;
  }

  .hello-text {
    font-size: 50px;
    margin-left: 50px;
  }

  /* Layout */
  .grid-container-wrapper {
    width: 85%;
    margin-left: 40px;
    max-width: 900px;
  }

  /* Headers */
  .header-courses,
  .header-recommended {
    font-size: 28px;
  }

  /* Enrolled Courses - Small Screen */
  .enrolled-courses-grid {
    grid-template-columns: 1fr;
    width: 95%;
    max-width: 600px;
  }
  
  .enrolled-course-card {
    padding: 30px;
    min-height: 0;
  }

  .enrolled-course-image,
  .enrolled-coding1-img,
  .enrolled-coding2-img,
  .enrolled-biochem-img,
  .enrolled-genetic-img,
  .enrolled-microbio-img {
    max-width: 200px;
    max-height: 200px;
  }

  .enrolled-course-content h1 {
    font-size: 32px;
  }

  .enrolled-course-content h2 {
    font-size: 16px;
    max-width: 90%;
  }

  .enrolled-zoom-btn,
  .enrolled-worksheet-btn {
    width: 160px;
    font-size: 16px;
    padding: 10px 0;
  }

  /* Recommended courses */
  .recommended-container {
    width: 100%;
    max-width: 100%;
  }

  .course-card {
    width: calc(33.33% - 20px);
  }

  .enrolled-explore-btn {
    max-width: 350px;
    height: 55px;
    font-size: 20px;
  }
}

/* Very small screens (mobile) */
@media screen and (max-width: 800px) {
  .hello {
    width: 90%;
    border-width: 20px;
  }

  .hello-text {
    font-size: 40px;
    margin-left: 20px;
  }

  .grid-container-wrapper {
    width: 80%;
    margin-left: 30px;
  }

  /* Enrolled Courses - Mobile */
  .enrolled-courses-grid {
    gap: 30px;
    max-width: 450px;
  }

  .enrolled-course-card {
    padding: 20px;
  }

  .enrolled-course-image,
  .enrolled-coding1-img,
  .enrolled-coding2-img,
  .enrolled-biochem-img,
  .enrolled-genetic-img,
  .enrolled-microbio-img {
    max-width: 150px;
    max-height: 150px;
  }

  .enrolled-course-content h1 {
    font-size: 26px;
  }

  .enrolled-course-content h2 {
    font-size: 14px;
  }

  .enrolled-zoom-btn,
  .enrolled-worksheet-btn {
    width: 130px;
    font-size: 14px;
  }

  .course-card {
    width: calc(50% - 20px);
    min-width: 150px;
  }

  .course-card-image {
    width: 100px;
    height: 100px;
  }

  .course-card-title {
    font-size: 1.2rem;
  }

  .course-card-description {
    font-size: 0.8rem;
  }

  .enrolled-explore-btn {
    max-width: 300px;
    height: 45px;
    font-size: 18px;
    padding: 10px 20px;
  }
}