.grid-side {
    position: fixed;
    background-color: #d3d3d3; 
    margin-left: 82%;
    margin-top: 90px;
    width: 18%;
    height: 100%;
    text-align: center;
    z-index: 100; 
    right: 0; 
    top: 0;
    overflow-y: auto; 
}

.badge {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 50px;
    background-color: #48A3BF;
    border-radius: 50%;
    width: 100px;
    text-align: center;
    line-height: 100px;
    color: white;
}

.info {
    margin-top: 30px;
    font-size: 20px;
}

.bar-buttons {
    margin-top: 30px;
    font-size: 15px;
    width: 100px;
    height: 40px;
    background-color: #357717;
    color: white;
    border: transparent;
    border-radius: 3px;
}

.bar-buttons:hover {
    cursor: pointer;
    background-color: #2a5f12; 
}


.grid-side button {
    position: relative;
    z-index: 101; 
}