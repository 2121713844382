.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
}

.header-bg {
    background-color: white;
    transition: 0.3s;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
    width: 270px;
    height: 75px;
    cursor: pointer;
    margin-top: 10px;
}

.nav-menu { 
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-menu li {
    padding: 0 1rem;
    position: relative;
    height: 90px;
    display: flex;
    align-items: center;
}

.nav-menu a {
    font-size: 1rem;
    font-weight: 500;
    /* Preserving your original font styles */
    text-decoration: none;
}

/* Fix for the dashboard button */
.login-item {
    display: flex;
    align-items: center;
}

.login-link {
    border: 2px solid green;
    padding: 8px 16px;
    border-radius: 3px;
    white-space: nowrap; 
    display: inline-block;
    color: green;
    text-decoration: none;
    transition: all 0.3s ease;
}

.login-link:hover {
    border-color: darkgreen;
    background-color: darkgreen;
    color: white;
}

/* Dropdown menu styling */
.nav-menu li:hover .dropdown-menu {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 90px;
    left: 0;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
    z-index: 20;
    min-width: 200px;
    border-radius: 4px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s;
}

.dropdown-menu li {
    padding: 8px 0;
    margin: 0;
    height: auto;
    display: block;
    text-align: left;
}

.dropdown-menu a {
    /* Preserving original dropdown font styles */
    color: black; 
    text-decoration: none; 
    font-size: 16px;
    display: block;
    padding: 5px 10px;
}

.dropdown-menu a:hover {
    color: green; 
}

.hamburger {
    display: none;
    cursor: pointer;
}

/* Mobile responsive styles */
@media screen and (max-width: 1100px) {
    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        top: 90px;
        left: -100%;
        text-align: center;
        width: 100%;
        height: calc(100vh - 90px);
        transition: 0.3s;
        z-index: 100;
        background: rgba(0, 0, 0, 0.9);
        overflow-y: auto;
        padding-top: 20px;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
        height: auto;
        width: 100%;
    }

    .nav-menu a {
        /* Preserving mobile font size from original */
        font-size: 2rem;
        color: white;
        display: block;
        padding: 10px 0;
    }

    /* Show dropdowns on click for mobile */
    .nav-menu li:hover .dropdown-menu {
        display: none;
    }
    
    .nav-menu li.active .dropdown-menu {
        display: block;
        position: relative;
        top: 10px;
        left: 0;
        width: 100%;
        background: transparent;
        box-shadow: none;
        opacity: 1;
        transform: none;
    }

    .dropdown-menu li {
        padding: 0.5rem 0;
    }

    .dropdown-menu a {
        color: #ccc;
        /* Keeping original dropdown font size */
        font-size: 16px;
    }

    /* Fix login button in mobile view */
    .login-item {
        margin-top: 10px;
    }

    .login-link {
        display: inline-block;
        color: white;
        border-color: green;
        background-color: green;
        padding: 10px 30px;
        /* Preserving original font size */
        font-size: 2rem;
    }
}